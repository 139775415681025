<template>
    <div class="gst-no-data text-center">
        <slot>
            <div class="my-2">
                <ImagePublicAssets class="d-inline-block" :path-key="iconSrc" :width="iconWidth" :height="iconHeight" />
            </div>
            <p class="gst-no-data__message">
                {{ msg }}
            </p>
        </slot>
    </div>
</template>

<script>
    import ImagePublicAssets from '@core/shared/components/images/ImagePublicAssets.vue';

    export default {
        name: 'NoData',
        components: {
            ImagePublicAssets
        },
        i18nOptions:{
            namespaces: 'shared',
            keyPrefix: 'components.misc.noData'
        },
        props: {
            message: {
                type: String,
                default: null
            },
            iconSrc: {
                type: String,
                default: 'FALLBACKS.NO_DATA'
            },
            iconWidth: {
                type: Number,
                default: 109
            },
            iconHeight: {
                type: Number,
                default: 127
            }
        },
        computed: {
            msg( ) {
                return this.message || this.$t( 'message' );
            }
        }
    };
</script>
<style lang="scss" scoped>
    @import "@scssVariables";

    .gst-no-data__message {
        color: theme-color( 'tertiary' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'large' );
    }
</style>
